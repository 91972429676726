const MEMBER_TYPE = {
  normal: '一般會員',
  vip: '預繳會員',
  enterprise: '一般企業',
  ent_vip: '企業預繳',
  monthly: '企業月結',
  ent_vip_monthly: '企業月結預繳會員',
};

const STAFF_TYPE = {
  staff: '員工',
  root: '管理員',
};

const UPGRADE_REQ_TO = {
  enterprise: '一般企業會員',
  ent_vip: '企業預繳會員',
  monthly: '月結會員',
  ent_vip_monthly: '企業月結預繳會員',
  // note
  // 只有企業 有月結功能
};

const UPGRADE_STATUS = {
  pending: '待審核',
  rejected: '已拒絕',
  accepted: '已通過',
};

const ORDER_TYPE = {
  // credits: "點數訂單", // in payment type
  default: '一般訂單',
  credit: '點數訂單', // in order type
  extra: '補收款訂單',
  custom: '自定義訂單',
  monthly: '月結訂單',
  period: '訂閱制訂單',
};

const ORDER_STATE = {
  payment_waiting: '等待付款',
  review_waiting: '稿件未上傳',
  review_pending: '等待審稿',
  review_rejected: '不合格',
  production_waiting: '製作等待中',
  /*
    production_preparing 工單已交付
    production_in_production 製作中
    display用order_items算的， filter 用 production_status
  */
  production_preparing: '工單已交付',
  production_in_production: '製作中',
  production_completed: '製作完成',
  logistic_pending: '出貨未處理',
  logistic_packaging: '打包中',
  logistic_tallying: '物流未完成',
  logistic_transit: '物流未完成', // 已出貨
  completed: '訂單完成',
  voided: '訂單已取消',
  ass_apply: '售後服務處理中',
  refunding: '退款處理中',
  refunded: '已完成退款',
};

const ORDER_STATUS_COLORS = {
  payment_waiting: '#9f9f9f',
  review_waiting: '#e6b104',
  review_pending: '#e6b104',
  review_rejected: '#d0543c',
  production_waiting: '#dd8022',
  production_preparing: '#dd8022',
  production_in_production: '#dd8022',
  production_completed: '#dd8022',
  logistic_pending: '#9acf6f',
  logistic_packaging: '#9acf6f',
  logistic_tallying: '#9acf6f',
  logistic_transit: '#9acf6f', // 已出貨
  completed: '#438ee3',
  voided: '#c6c6c6',
  ass_apply: '#72c0de',
  refunding: '#c2a5e2',
  refunded: '#c2a5e2',
};

const PAYMENT_TYPE = {
  all: '全部',
  neweb: '藍新付款',
  payuni: '統一付款',
  credit: '點數付款',
  credits: '點數付款',
  ecpay_cvs: '超商付款',
  ecpay: '信用卡付款',
  ecpay_atm: 'ATM繳費',
  offline: '臨櫃匯款',
  monthly: '月結付款',
};

const PAYMENT_SUB_TYPE = {
  default: '尚未選擇',
  credit: '信用卡付款',
  cvs_cod: '超商取貨付款',
  cvs: '超商繳費',
  atm: 'ATM付款',
  web_atm: '網路ATM付款',
  barcode: '超商條碼繳費',
  line_pay: 'Line Pay',
};

const PAYMENT_STATUS = {
  all: '全部',
  processing: '未付款',
  waiting: '未付款',
  code_generated: '未付款', //已取得交易序號
  success: '已付款',
  failure: '付款失敗',
};

const PAYMENT_STATUS_COLORS = {
  processing: '#c6c6c6',
  waiting: '#c6c6c6',
  code_generated: '#c6c6c6', //已取得交易序號
  success: '#9acf6f',
  failure: '#d0543c',
};

const REVIEW_STATUS = {
  waiting: '稿件未上傳',
  pending: '等待審稿',
  accepted: '合格',
  rejected: '不合格',
  reupload: '已補件',
  void: '作廢',
};

const REVIEW_STATUS_COLORS = {
  waiting: '#c5c6c5',
  pending: '#f8cb23',
  accepted: '#99cf6f',
  rejected: '#d0543c',
  reupload: '#dd8021',
  void: '#d0543c',
};

const PRODUCTION_STATUS = {
  waiting: '等待中',
  pending: '-',
  preparing: '工單已交付',
  in_production: '製作中',
  completed: '印製完成',
  in_storage: '已入庫',
};

const PRODUCTION_STATUS_COLORS = {
  waiting: '#c5c6c5',
  pending: '#c5c6c5',
  preparing: '#f8cb23',
  in_production: '#dd8021',
  completed: '#99cf6f',
  in_storage: '#99cf6f',
};

const DELIVERY_TYPE = {
  self_pick: '自取',
  special_car: '專車配送',
  mailing: '宅配-郵局包裹',
  hct: '宅配-新竹貨運',
  ezship: '台灣便利配',
  tcat: '宅配-黑貓宅急便',
  kerry_tj: '宅配-大榮貨運',
  maple: '宅配-便利帶',
};

const RETURN_STATUS = {
  all: '全部',
  pending: '等待處理',
  supplying: '補印件中',
  supplement_completed: '已完成補印件',
  returning: '退款',
  return_completed: '售後服務完成',
  rejected: '退貨條件不符，已拒絕退貨',
};

const RETURN_REASON = [
  '重複下單',
  '商品款式錯誤 (例如：尺寸、顏色、圖案不符等)',
  '商品有瑕疵',
  '商品有損毀',
  '其他問題（填入備註）',
];

const REFUND_STATUS = {
  all: '全部',
  waiting: '退款處理中',
  success: '退款已完成',
  failure: '退款已取消',
};

const REFUND_TYPES = {
  partial: '部分金額手動/轉帳退款',
  transfer_full: '全額手動/轉帳退款',
  full: '全額信用卡刷退',
  payuni_offline: '統一金流退款轉匯',
  payuni_line_pay: '統一金流 Line Pay 退款',
};

const HISTORY_MODEL = {
  Order: '訂單',
  OrderItemReview: '稿件',
  Logistics: '物流',
  Invoice: '發票',
  ReturnApplication: '售後',
  Refund: '退款',
  'Extra Order': '補收',
  UserProfile: '個人資料',
  MonthlyOrder: '月結',
};

const HISTORY_USERS = {
  system: '系統',
  neweb: '藍星',
  trackingmore: 'trackingmore',
  ecpay: '藍星發票',
};

const HISTORY_FIELDS = {
  Order: {
    payment_type: '付款方式',
    payment_status: '付款狀態',
    code_no: '繳費代碼/轉帳帳號',
    pay_time: '藍新付款時間',
    staff_note: '員工備註',
    urgent_time: '急件時間',
    coupon: '使用促銷代碼',
    voided: '作廢',
  },
  OrderItemReview: {
    note: '審稿人員備註',
    note_2: '客戶傳檔備註',
    file: '傳檔',
    status: '審稿狀態',
    production_status: '製作狀態',
    comment: '留言',
    supplier: '廠商',
    thumbnail: '縮圖',
    thumbnail_url: '縮圖',
    assignee: '審稿人員',
    reject_reason: '不合格原因',
  },
  Logistics: {
    logistics_type: '物流方式',
    logistics_status: '物流狀態',
    tracking_number: '追蹤單號',
  },
  Invoice: {
    invoice_status: '開立狀態',
    invoice_number: '發票號碼',
  },
  ReturnApplication: {
    status: '售後狀態',
    file: '檔案',
    rejected_reason: '申請原因',
  },
  Refund: {
    amount: '金額',
    note: '備註',
    refund_type: ' 退款方式',
    status: ' 退款狀態',
  },
  'Extra Order': {
    extra_type: '原因',
    description: '備註',
    amount: '金額',
  },
  UserProfile: {
    credits: '點數',
    bonus: '紅利',
    value: '數值',
    _credits: '原點數',
    _bonus: '原紅利',
    diff: '差值',
    note: '備註',
    feedback: '回饋',
    feedback_bonus: '紅利回饋',
    feedback_credits: '點數回饋',
    feedback_credit: '點數回饋',
  },
  MonthlyOrder: {
    remittance_account: '匯款戶名',
    remittance_number: '匯款後五碼',
    remitted_time: '匯款時間',
    payment_status: '繳費狀態',
    note: '備註',
  },
};

const EZSHIP_STATE = {
  TOK: 'OK便利商店',
  TLF: '萊爾富',
  TFM: '全家',
};

const MONTHLY_TYPE = {
  '1_in_month': '每月1號',
  '5_in_month': '每月5號',
  '10_in_month': '每月10號',
  '15_in_month': '每月15號',
  '20_in_month': '每月20號',
  '25_in_month': '每月25號',
  last_in_month: '每月最後一天',
};

const MONTHLY_ORDER_STATUS = {
  waiting: '未繳費',
  remitted: '待對帳',
  success: '已繳費',
  expired: '已過期',
};

const MONTHLY_ORDER_STATUS_COLOR = {
  waiting: '#c6c6c6',
  remitted: '#4690f7',
  success: '#9acf6f',
  expired: '#d0543c',
};

const LOGISTIC_TRACKING_URL = {
  hct: 'https://www.hct.com.tw/search/searchgoods_n.aspx',
  kerry_tj: 'http://www.kerrytj.com/ZH/search/search_track.aspx',
  tcat: 'https://www.t-cat.com.tw/inquire/trace.aspx',
  mailing: 'https://postserv.post.gov.tw/pstmail/main_mail.html',
  ezship:
    'https://www.ezship.com.tw/receiver_query/ezship_query_shipstatus_2017.jsp',
  ezship_home: 'http://www.ezship.com.tw/index.jsp#',
  maple: 'https://www.25431010.tw/tracking',
};

const PERIOD_STATUS = {
  activity: '啟用', //（預設）
  review_waiting: '申請取消中',
  extra_payment_waiting: '補收款中',
  rejected: '拒絕取消',
  terminate: '終止',
};

const EXTRA_ORDER_TYPE = {
  shipping_fee: '運費',
  item_fee: '產品加工',
  construction_funds: '施工款項',
  urgent_increase: '急件加價',
  custom: '自定義商品',
};

const INVOICE_STATUS = {
  success: '開立成功',
  failure: '開立失敗',
  voided: '作廢', // api callback
  void: '作廢', // backend serialize for frontend
};

const INVOICE_AFTER_TREATMENT_STATE = {
  default: '未處理',
  neweb: '已於藍新重開',
  dashboard: '已於後台重開',
};

const TAX_TYPE = {
  1: '應稅',
  2: '零稅率',
  3: '免稅',
  9: '混合應稅與免稅或零稅率',
};

const CARRIER_TYPE = {
  0: '手機載具',
  1: '自然人憑證載具',
  2: 'EZPay載具',
};

const PROMO_TYPE = {
  banner: 'Banner',
  top_zone: 'Top Zone',
  bottom_zone: 'Bottom Zone',
};

const PROMO_HINT = {
  banner: '首頁輪播橫幅',
  top_zone: '首頁橫幅下方的促銷專區',
  bottom_zone: '首頁熱門商品下方的促銷專區，及商品分類旁的促銷專區',
};

const PROMOTION_TYPE = {
  CREDIT_FEEDBACK: 'CREDIT_FEEDBACK',
  BONUS_FEEDBACK: 'BONUS_FEEDBACK',
  MEMBER_DISCOUNT: 'MEMBER_DISCOUNT',
  GIFT_BONUS: 'GIFT_BONUS',
  LOGISTIC_FEE: 'LOGISTIC_FEE',
  THRESHOLD_DISCOUNT: 'THRESHOLD_DISCOUNT',
};

module.exports = {
  MEMBER_TYPE,
  STAFF_TYPE,
  UPGRADE_REQ_TO,
  UPGRADE_STATUS,
  HISTORY_MODEL,
  HISTORY_USERS,
  HISTORY_FIELDS,
  EZSHIP_STATE,
  MONTHLY_TYPE,
  MONTHLY_ORDER_STATUS,
  MONTHLY_ORDER_STATUS_COLOR,
  LOGISTIC_TRACKING_URL,
  PERIOD_STATUS,
  ORDER_STATUS_COLORS,
  PAYMENT_STATUS_COLORS,
  REVIEW_STATUS,
  REVIEW_STATUS_COLORS,
  PRODUCTION_STATUS,
  PRODUCTION_STATUS_COLORS,
  ORDER_TYPE,
  ORDER_STATE,
  PAYMENT_TYPE,
  PAYMENT_SUB_TYPE,
  PAYMENT_STATUS,
  DELIVERY_TYPE,
  RETURN_STATUS,
  RETURN_REASON,
  REFUND_STATUS,
  REFUND_TYPES,
  EXTRA_ORDER_TYPE,
  INVOICE_STATUS,
  INVOICE_AFTER_TREATMENT_STATE,
  TAX_TYPE,
  CARRIER_TYPE,
  PROMO_TYPE,
  PROMO_HINT,
  PROMOTION_TYPE,
};
